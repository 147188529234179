import React from "react";

export default function Contact() {
  return (
    <section className="overflow-hidden relative z-10" id="lets-talk">
      <div className="container">
        <div className="flex flex-wrap lg:justify-between -mx-4">
          <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
            <div className="max-w-[570px] mb-12 lg:mb-0">
              <h2
                className="
                  text-dark
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                  "
              >
                Let's <span className="text-teal-500">Talk</span>
              </h2>
              <p className="text-base text-body-color leading-relaxed mb-9">
                Feel free to contact via email.
              </p>
              <div className="flex mb-8 max-w-[370px] w-full">
                <div
                  className="
                     max-w-[60px]
                     sm:max-w-[70px]
                     w-full
                     h-[60px]
                     sm:h-[70px]
                     flex
                     items-center
                     justify-center
                     mr-6
                     overflow-hidden
                     bg-primary bg-opacity-5
                     text-primary
                     rounded
                     "
                >
                  <svg
                    width="28"
                    height="19"
                    viewBox="0 0 28 19"
                    className="fill-current text-teal-500"
                  >
                    <path d="M25.3636 0H2.63636C1.18182 0 0 1.16785 0 2.6052V16.3948C0 17.8322 1.18182 19 2.63636 19H25.3636C26.8182 19 28 17.8322 28 16.3948V2.6052C28 1.16785 26.8182 0 25.3636 0ZM25.3636 1.5721C25.5909 1.5721 25.7727 1.61702 25.9545 1.75177L14.6364 8.53428C14.2273 8.75886 13.7727 8.75886 13.3636 8.53428L2.04545 1.75177C2.22727 1.66194 2.40909 1.5721 2.63636 1.5721H25.3636ZM25.3636 17.383H2.63636C2.09091 17.383 1.59091 16.9338 1.59091 16.3499V3.32388L12.5 9.8818C12.9545 10.1513 13.4545 10.2861 13.9545 10.2861C14.4545 10.2861 14.9545 10.1513 15.4091 9.8818L26.3182 3.32388V16.3499C26.4091 16.9338 25.9091 17.383 25.3636 17.383Z" />
                  </svg>
                </div>
                <a href="mailto:andyfransisko1@gmail.com">
                  <div className="w-full">
                    <h4 className="font-bold text-dark text-xl mb-1">
                      Email Address
                    </h4>
                    <p className="text-base text-body-color">
                      andyfransisko1@gmail.com
                    </p>
                  </div>
                </a>
              </div>
              <p className="text-base text-body-color leading-relaxed mb-4">
                Follow my social media.
              </p>
								<a href="http://www.instagram.com/andyfransisko" target="_blank" rel="noreferrer">
                  <i className="fa fa-instagram cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
                </a>
                <a href="https://www.linkedin.com/in/andyfransisko/" target="_blank" rel="noreferrer">
                  <i className="fa fa-linkedin-square cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
                </a>
                <a href="https://dribbble.com/andyfransisko" target="_blank" rel="noreferrer">
                  <i className="fa fa-dribbble cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
                </a>
                <a href="http://behance.net/andyfransisko" target="_blank" rel="noreferrer">
                  <i className="fa fa-behance cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
                </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
