import React from 'react';

import './index.css';

import Landing from './assets/components/Landing';
import AboutMe from './assets/components/AboutMe';
import Experiences from './assets/components/Experiences';
import Contact from './assets/components/Contact';

function App() {

  return (
    <>
      <div className="container mx-auto px-8 font-sans mb-12">
        <Landing />
        <AboutMe />
        <Experiences />
        <Contact />
      </div>
    </>
  );
}

export default App;
