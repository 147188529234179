import React, { useState } from "react";
import ReactTypingEffect from 'react-typing-effect';

import "../../index.css";

//Picture
import HomePic from "../images/Home-2.png";

export default function Landing() {

  const [myJob] = useState(["Software Engineer.", "UI/UX Designer."]);
  return (
    <div className="flex mt-24 flex-col lg:flex-row">
      <div className="flex-1">
        <h1 className="font-bold text-4xl md:text-6xl tracking-wide">
          Hello<span className="text-teal-500">,</span> I am <br />
          Andy Fransisko<span className="text-teal-500">.</span>
        </h1>
        <h2 className="text-xl mt-12">
          <ReactTypingEffect
            text={myJob}
            speed={200}
            eraseSpeed={300}
            typingDelay={1500}
          />
        </h2>
        <div className="mt-10">
          <div className="flex mt-2">
            <div className="home-list flex-initial w-14 h-14 self-center bg-teal-500" />
            <p className="flex-auto ml-6 cursor-pointer text-lg gray-font">
              <a href="#about-me">
                <span className="hover:text-white">About Me</span>
              </a>
            </p>
          </div>
          <div className="flex mt-2">
            <div className="home-list flex-initial w-14 h-14 self-center bg-teal-500" />
            <p className="flex-auto ml-6 cursor-pointer text-lg gray-font">
              <a href="#experiences">
                <span className="hover:text-white">My Experience</span>
              </a>
            </p>
          </div>
          <div className="flex mt-2">
            <div className="home-list flex-initial w-14 h-14 self-center bg-teal-500" />
            <p className="flex-auto ml-6 cursor-pointer text-lg gray-font">
              <a href="#lets-talk">
                <span className="hover:text-white">Let's Talk</span>
              </a>
            </p>
          </div>
        </div>
        <div className="mt-16">
          <a href = "http://www.instagram.com/andyfransisko" target="_blank" rel="noreferrer">
            <i className="fa fa-instagram cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
          </a>
          <a href = "https://www.linkedin.com/in/andyfransisko/" target="_blank" rel="noreferrer">
            <i className="fa fa-linkedin-square cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
          </a>
          <a href = "https://dribbble.com/andyfransisko" target="_blank" rel="noreferrer">
            <i className="fa fa-dribbble cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
          </a>
          <a href = "http://behance.net/andyfransisko" target="_blank" rel="noreferrer">
            <i className="fa fa-behance cursor-pointer text-2xl text-white mr-4 hover:text-teal-500"></i>
          </a>
        </div>
      </div>
      <div className="flex-1 self-center">
        <img src={HomePic} width="100%" alt="Andy" />
      </div>
    </div>
  );
}
