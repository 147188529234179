import React, { useState } from "react";
import Timeline from "./Timeline";

export default function Experiences() {
  const [experiencesInformation] = useState([
    {
      id: 1,
      name: "SMA Pelita Nusantara Tanjungpinang",
      timeline: "Graduated on 2017",
      desc: "",
      current: false,
      type: "education",
    },
    {
      id: 2,
      name: "Universitas Pelita Harapan",
      timeline: "2017 - 2021 (3.4 out of 4 GPA)",
      desc: "Information Systems",
      current: false,
      type: "education",
    },
    {
      id: 3,
      name: "Frontend Developer at Gandiwa Nusantara",
      timeline: "January 2021 - July 2021",
      desc: "Develop and Maintain Company’s Website. Responsible for implementing visual elements that users see and interact with in a web application and ensuring websites are accessible across many platforms, including laptops and smartphones.",
      current: false,
      type: "work",
    },
    {
      id: 4,
      name: "Digital Solution Developer at Bank Sinarmas",
      timeline: "July 2021 - May 2022",
      desc: "Develop and evaluate the company’s software application to ensure that it is valid, is properly structured, meets industry standards, and is well integrated with each other.",
      current: false,
      type: "work",
    },
    {
      id: 5,
      name: "Software Engineer at Tokopedia",
      timeline: "May 2022 - Now",
      desc: "Mainly develop, maintain and improve platform software systems. As a part of search team, collaborate across teams to create solutions that enhance the search experience to drive the business growth.",
      current: true,
      type: "work",
    },
  ]);

  return (
    <section id="experiences">
      <div className="py-10">
        <h3 className="font-bold text-3xl">
          <span className="text-teal-500">My</span> Experiences
        </h3>

        <div className="flex flex-col lg:flex-row mt-12">
          <div className="flex-1">
            <h4 className="font-bold text-2xl">Education</h4>
            <ol className="relative border-l border-gray-700 mt-6">
              {experiencesInformation.map((data) => {
                if (data.type === "education")
                  return <Timeline data={data} key={data.id} />;
              })}
            </ol>
          </div>
          <div className="flex-1">
            <h4 className="font-bold text-2xl">Work Experience</h4>
            <ol className="relative border-l border-gray-700 mt-6">
              {experiencesInformation.map((data) => {
                if (data.type === "work")
                  return <Timeline data={data} key={data.id} />;
              })}
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}
