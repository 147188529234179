import React from "react";

export default function AboutMe() {
  return (
    <section id="about-me" className="py-6">
      <div className="mt-12 mb-6 about-bg rounded-xl px-6 md:px-12 py-8">
        <h3 className="font-bold text-3xl">
          About <span className="text-teal-500">Me</span>
        </h3>
        <p className="mt-8 gray-font">
          Energetic and flexible website/application developer with over 5 years
          of experience participating in the full software development life-cycle
          from planning to launch. A good timekeeper, always willing to keep
          learning and training to gain further useful skills and qualifications
          both in frontend or backend. Working with people from diverse
          backgrounds has provided me with a deep understanding and awareness of
          differences in both individuals and organizations.
        </p>
      </div>
    </section>
  );
}
