import React from "react";

export default function Timeline(props) {
	
	const CurrentTag = () => {
    return (
      <span className="text-gray-400 text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-gray-800 ml-3">
        Current
      </span>
    );
  };

  return (
    <li className="mb-10 ml-6">
      <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-gray-900 green-bg">
				{ props.data.type === "education" && <i className="fa fa-graduation-cap text-xs text-black"></i> }
				{ props.data.type === "work" && <i className="fa fa-briefcase text-xs text-black"></i> }
      </span>
      <h3 className="flex items-center mb-1 text-lg font-semibold text-white">
        {props.data.name}
        {props.data.current && <CurrentTag />}
      </h3>
      <time className="block mb-2 text-sm font-normal leading-none text-gray-500">
        {props.data.timeline}
      </time>
      <p className="mb-4 text-base font-normal text-gray-400">{props.data.desc}</p>
    </li>
  );
}
